$dark-bg: #343a40;
$threedot-button-size: 30px;
$steintex-background-color: #ececec;



.twocolumn-table{
  table-layout: fixed;
  width:100%;
}


.twocolumn-table>tbody>tr>:last-child{
  text-align: right;
}

.qr-code{
  max-width:100%;
}


.twocolumn-table a{
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

body {
  background-color: $steintex-background-color;
}


.image {
  background-image: url("../images/dots.svg");
  background-size: $threedot-button-size;
  height: $threedot-button-size;
  width: $threedot-button-size;
}

.image > div {
  transition: all 0.15s ease-out;
  clip-path: circle(0px at center);
  background-image: url("../images/dots_pressed.svg");
  background-size: $threedot-button-size;
  height: $threedot-button-size;
  width: $threedot-button-size;
}

.image:hover > div {
  clip-path: circle($threedot-button-size /2 at center);
}

#logo{
 margin-top: 8px;
 margin-right: 24px;
}

.btn-group>a{
  padding-left: 0 !important;
}