$primaryColor: #1e376e;
$secondaryColor: #3766cc;


body {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.btn-primary{
  background-color: $primaryColor;
  border-color: $primaryColor;
}

.btn-primary:hover{
  background-color: $secondaryColor;
  border-color: $secondaryColor;
}

footer {
  flex-shrink: 0;
}


main {
  flex: auto;
}


#flash-container{
  z-index: 100;
  position: fixed;
  right: 15px;
  top:15px;
}

.footer {
  width: 100%;
  height: 60px;
  line-height: 60px;
}

.footer ul {
  margin: 0;
}

.active>a{
  background-color: $primaryColor !important;
  border-color: $primaryColor !important;
}